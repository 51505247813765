var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"4","width":"1000"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',{staticClass:"headline mb-0"},[_c('b',[_vm._v("الزيارات")])])]),_c('v-divider'),_c('v-card-text',[_c('v-simple-table',{attrs:{"fixed-header":"","width":"900"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("رقم الزيارة")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("اسم المهمة")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("مصدر المعلومات")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("الملاحظات")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("تاريخ البداية")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("تاريخ النهاية")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("خيارات")])])])]),(_vm.visits.length > 0)?_c('tbody',_vm._l((_vm.visits),function(item){return _c('tr',{key:item.idvisit},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.idvisit))]),_c('td',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(item.task.name))])]),_c('td',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(item.infoSource))])]),_c('td',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(item.notes))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.task.startDate))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.task.endDate))+" ")]),_c('td',{staticClass:"text-center"},[_c('div',{staticStyle:{"display":"flex"},attrs:{"align":"center"}},[_c('v-btn',{staticStyle:{"background":"#58dd5b","color":"white","margin-left":"5px"},attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'external-visit',
                              params: {
                                externalId: _vm.$route.params.externalId,
                                visitId: item.idvisit,
                                visit_details: item
                              }
                            })}}},[_c('b',[_vm._v("عرض")])])],1)])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"color":"gray","font-size":"12px"},attrs:{"colspan":"7"}},[_vm._v(" لا توجد بيانات لعرضها ")])])])]},proxy:true}])})],1),_c('v-divider'),_c('v-container')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("هل أنت متأكد من الحذف؟")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info darken-1","text":""},on:{"click":_vm.hideDialog}},[_c('b',[_vm._v("إلغاء")])]),_c('v-btn',{staticStyle:{"background-color":"#5867dd","color":"white"},attrs:{"text":""},on:{"click":_vm.deleteTask}},[_c('b',[_vm._v("موافق")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }