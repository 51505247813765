<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0"><b>الزيارات</b></h4>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-simple-table fixed-header width="900">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center"><b>رقم الزيارة</b></th>
                    <th class="text-center"><b>اسم المهمة</b></th>
                    <th class="text-center"><b>مصدر المعلومات</b></th>
                    <th class="text-center"><b>الملاحظات</b></th>
                    <th class="text-center"><b>تاريخ البداية</b></th>
                    <th class="text-center"><b>تاريخ النهاية</b></th>
                    <th class="text-center"><b>خيارات</b></th>
                  </tr>
                </thead>
                <tbody v-if="visits.length > 0">
                  <tr v-for="item in visits" :key="item.idvisit">
                    <td class="text-center">{{ item.idvisit }}</td>
                    <td class="text-center">
                      <b>{{ item.task.name }}</b>
                    </td>
                    <td class="text-center">
                      <b>{{ item.infoSource }}</b>
                    </td>
                    <td class="text-center">
                      <b>{{ item.notes }}</b>
                    </td>
                    <td class="text-center">
                      {{ createdDate(item.task.startDate) }}
                    </td>
                    <td class="text-center">
                      {{ createdDate(item.task.endDate) }}
                    </td>
                    <td class="text-center">
                      <div style="display:flex;" align="center">
                        <v-btn
                          small
                          @click="
                            $router.push({
                              name: 'external-visit',
                              params: {
                                externalId: $route.params.externalId,
                                visitId: item.idvisit,
                                visit_details: item
                              }
                            })
                          "
                          style="background:#58dd5b;color:white;margin-left:5px;"
                          dark
                        >
                          <b>عرض</b>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="7"
                      class="text-center"
                      style="color:gray;font-size:12px;"
                    >
                      لا توجد بيانات لعرضها
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-container>
            <!--   <div class="text-center">
                              <v-pagination
                                v-model="page"
                                :length="totalItems"
                                :total-visible="7"
                               ></v-pagination>
                            </div>-->
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">هل أنت متأكد من الحذف؟</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="info darken-1" text @click="hideDialog">
            <b>إلغاء</b>
          </v-btn>

          <v-btn
            style="background-color:#5867dd;color:white"
            text
            @click="deleteTask"
          >
            <b>موافق</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      taskTypes: [
        { value: "0", item: "اختر نوع الزيارة" },
        { value: "1", item: "كل الزيارات " },
        { value: "2", item: "زيارات جديدة" },
        { value: "3", item: "زيارات منجزة" },
        { value: "4", item: "زيارات بانتظار المراجعة" },
        { value: "5", item: "زيارات قيد التقدم" }
      ],
      selectedTaskType: "",
      visits: [],
      showAcceptButton: false,
      dialog: false,
      deleteVisit: 0,
      page: 1,
      totalItems: 0,
      user: null,
      role: null
    };
  },
  methods: {
    showDialog(id) {
      this.dialog = true;
      this.deleteVisit = id;
    },
    hideDialog() {
      this.dialog = false;
      this.deleteVisit = 0;
    },
    getAllvisits(id) {
      this.ApiService.get("visit/visitsByExternal?external=" + id)
        .then(res => {
          this.visits = [];
          this.visits = res.data.responseData;
        })
        .catch(() => {});
    },
    deleteTask() {
      this.dialog = false;
      this.ApiService.delete("task/DeleteTask/" + this.deleteVisit)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.getAllvisits(1);
          this.deleteVisit = 0;
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
    },
    detailsTask(task) {
      this.$router.push({ name: "visit-details", params: task });
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.external = this.$route.params.external;
    if (this.external == null) {
      this.$router.go(-1);
    }
    this.getAllvisits(this.$route.params.externalId);
  }
};
</script>
<style lang="scss"></style>
